import styles from "./Home.module.scss";
import About from "components/About";
import Blog from "components/Blog";
import Contact from "components/Contact";
import Footer from "components/Footer";
import MenuButton from "components/Header/MenuButton";
import Project from "components/Project";
import Menu from "components/Header/Menu";
import { useRecoilValue } from "recoil";
import { menuAtom } from "recoil/Header";
import ToggleLang from "components/Header/ToggleLang";
import { useEffect, useState } from "react";
import Loader from "components/Loader";

export default function HomePage() {
  const menu = useRecoilValue(menuAtom);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [loading]);

  return loading ? (
    <Loader />
  ) : (
    <div className={styles.home}>
      <header>
        <MenuButton />
        <Menu />
        {!menu && <ToggleLang />}
      </header>
      <div className={styles.home}>
        <About />
        <Project />
        <Blog />
        <Contact />
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
