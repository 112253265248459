import { useTranslation } from "react-i18next";
import styles from "./Contact.module.scss";
export default function Contact() {
  const { t } = useTranslation();

  return (
    <div id="contact" className={styles.contact}>
      <a href="mailto:lecramproject@gmail.com">
        <span className={styles.text}>{t("contactMe")}</span>
        <svg
          className={styles.arrow}
          width="100%"
          height="100%"
          viewBox="0 0 107 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M106.414 16.9146C107.195 16.1336 107.195 14.8672 106.414 14.0862L93.6863 1.35825C92.9052 0.577199 91.6389 0.577199 90.8579 1.35825C90.0768 2.13929 90.0768 3.40562 90.8579 4.18667L102.172 15.5004L90.8579 26.8141C90.0768 27.5952 90.0768 28.8615 90.8579 29.6425C91.6389 30.4236 92.9052 30.4236 93.6863 29.6425L106.414 16.9146ZM0 17.5004H105V13.5004H0V17.5004Z"
            fill="#111013"
          ></path>
        </svg>
      </a>
    </div>
  );
}
