import { menuAtom } from "recoil/Header";
import styles from "./Header.module.scss";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

export default function Menu() {
  const [menu, setMenu] = useRecoilState(menuAtom);

  const { t } = useTranslation();

  const handleMenu = () => {
    setMenu(!menu);
  };

  return (
    <div
      className={` ${styles.menuDiv} ${
        menu ? styles.showMenu : styles.closeMenu
      }`}
    >
      <ul className={styles.menuList}>
        <li className={styles.item}>
          <a href="#about" onClick={handleMenu}>
            {t("about")}
          </a>
        </li>
        {/* <li className={styles.item}>
          <a href="#projects" onClick={handleMenu}>{t("projects")}</a>
        </li>
        <li className={styles.item}>
          <a href="#blog" onClick={handleMenu}>{t("blog")}</a>
        </li>
        <li className={styles.item}>
          <a href="#prices" onClick={handleMenu}>{t("prices")}</a>
        </li> */}
        <li className={styles.item}>
          <a href="#contact" onClick={handleMenu}>
            {t("contact")}
          </a>
        </li>
      </ul>
      <button className={styles.closeMenuButton} onClick={handleMenu}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 60 60"
          width="60"
          height="60"
          overflow="visible"
          strokeWidth="3"
        >
          <line x1="0" y1="0" x2="50" y2="50" />
          <line x1="50" y1="0" x2="0" y2="50" />
        </svg>
      </button>
    </div>
  );
}
