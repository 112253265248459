import { atom } from "recoil";

export const menuAtom = atom<boolean>({
    key: "menu",
    default: false,
  });  
export const atomToggleLang = atom<boolean>({
    key: "toogleLang",
    default: false,
  });
  