import { useTranslation } from "react-i18next";
import me from "assets/clementmarcel.jpg"; // with import
import styles from "./About.module.scss";

export default function About() {
  const { t } = useTranslation();

  return (
    <div id="about" className={styles.about}>
      <div className={styles.me}>
        <h1>Clément MARCEL</h1>
        <h2>{t("work")}</h2>
        <h2>©2023</h2>
        <p className={styles.who}>{t("who")}</p>
      </div>
      <img className={styles.photo} src={me} alt="clementmarcel" />
    </div>
  );
}
