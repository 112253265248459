import { useEffect, useRef } from "react";
import styles from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { atomToggleLang } from "recoil/Header";

export default function ToggleLang() {
  const { i18n } = useTranslation();
  const [checked, setChecked] = useRecoilState(atomToggleLang);

  let lang = useRef("fr");

  useEffect(() => {
    checked ? (lang.current = "en") : (lang.current = "fr");
    i18n.changeLanguage(lang.current);
  }, [checked, i18n]);

  return (
    <div className={styles.toggleLang}>
      <label className={`${styles.btnLangSwitch} ${styles.switch}`}>
        <input
          type="checkbox"
          checked={checked}
          id="lang"
          onChange={() => setChecked(!checked)}
        />
        <label
          className={styles.btnLangSwitchInner}
          htmlFor="lang"
          data-on="en"
          data-off="fr"
        ></label>
      </label>
    </div>
  );
}
