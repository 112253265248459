import { menuAtom } from "recoil/Header";
import styles from "./Header.module.scss";
import { useRecoilState } from "recoil";
export default function MenuButton() {
  const [menu, setMenu] = useRecoilState(menuAtom);

  const handleMenu = () => {
    setMenu(!menu);
  };

  return (
    <div className={styles.menuButtonDiv}>
      <button onClick={handleMenu}>
        <span>Menu</span>
      </button>
    </div>
  );
}
