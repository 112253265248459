import styles from "./Footer.module.scss";
import linkedin from "../../assets/linkedin.png"; // with import

export default function Footer() {
  return (
    <div className={styles.footer}>
      <h4 className={styles.link}>
        <a
          className={styles.linkedin}
          href="https://www.linkedin.com/in/clementmarcel/"
          target="_blank"
          rel="noreferrer"
        >
          <img className={styles.logo} src={linkedin} alt="" />
          Linkedin
        </a>
      </h4>
      <h4 className={styles.copyright}>©2023</h4>
    </div>
  );
}
